<template lang="pug">
  export-excel.ma-0(
    :data="computedValue"
    :fields="dataFields"
    type="xls"
    :name="name"
    :before-generate="beforeGenerate"
  )
    v-btn.ma-0(
      dark
      color="success"
      small
      block
    )
      span Export Excel V2
</template>
<script>
const generateExcelName = () => `orders_report_${new Date().getTime()}.xls`

const dataFields = {
  Company: 'company',
  'Invoice No.': 'invoiceNumber',
  Item: 'item',
  'Order date': 'orderDate',
  Qty: 'quantity',
  Unit: 'unit',
  'Unit Price': 'unitPrice',
  Discount: 'discount',
  Amount: 'amount',
  Status: 'status',
}

const getAmount = item => (item.unit_price - item.discount) * item.quantity

const transformValue = item => item.map(item => item.details.map(subItem => ({
  company: item.customer.company,
  item: subItem.stock.description,
  orderDate: item.order_date,
  invoiceNumber: Window.intToInvoiceNumber(item.invoice_number),
  quantity: subItem.quantity,
  unit: subItem.unit,
  unitPrice: subItem.unit_price,
  discount: subItem.discount,
  amount: getAmount(subItem),
  status: item.status.name,
}))).flat()

const getTotalAmount = items => items.reduce((totalA, item) => totalA + item.details.reduce((totalB, subItem) => getAmount(subItem) + totalB, 0), 0)

export default {
  name: 'ExportOrdersV2',
  props: {
    value: {
      type: Array,
      default: () => ({}),
    },
  },
  data () {
    return {
      dataFields,
      name: null,
    }
  },
  computed: {
    computedValue () {
      return [
        ...transformValue(this.value),
        {
          company: 'Total',
          amount: getTotalAmount(this.value),
        },
      ]
    },
  },
  methods: {
    beforeGenerate () {
      this.name = generateExcelName()
    },
  },
}
</script>